import React, { useEffect, useState } from 'react';
import { useMobile } from "../../hooks/hooks";
import Button from '../ui/Button';
import { WidthContainer } from '../WidthContainer';
import img from "./Retail-Webinar-blog-image.webp";
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../../constants/analytics"
import { navigate } from 'gatsby-link';
import {useTranslation} from "react-i18next";

const ContactAmbassadeurs = ({ }) => {
    const { mobile } = useMobile()
    const [ linkToAmbassadorRegistrationClicked, setLinkToAmbassadorRegistrationClicked ] = useState(false);
    const { t } = useTranslation();
    // analytics - the button that sends the user to the register ambassador form was clicked
    useEffect(() => {
        if(firebase && linkToAmbassadorRegistrationClicked) {
            firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_ambassador_subscription_sent)
            setLinkToAmbassadorRegistrationClicked(false)
            navigate('https://bo.behappiz.com/partner/register');
        }
    }, [firebase, linkToAmbassadorRegistrationClicked])

    return (
        <WidthContainer center>

            <div
                id="ContactAmbassadeurs"
                className=""
                {...{
                    style: {
                        display: "grid",
                        gridTemplate: mobile ? `
                        "image" 
                        "content" 
                        `: `
                            "content image" 
                        `,
                        gap: mobile ? "48px" : "48px",
                        marginBottom: mobile ? "96px" : "96px",
                        // padding: "48px",
                        placeItems: "center",
                        // marginTop: "-10%"
                    },
                }}
            >

                <h2   {...{
                    style: {
                        gridArea: "content",
                        textAlign: "center"
                    },
                }}>
                    <div   {...{
                        style: {
                            fontSize: !mobile ? "42px" : "20px",
                            fontWeight: 700
                        },
                    }}>
                        {t('ambassador.subscribe')}
                    </div>
                    <div   {...{
                        style: {
                            fontSize: !mobile ? "42px" : "20px",
                            fontWeight: 700,
                            color: "#366EC0"
                        },
                    }}>
                        {t('ambassador.toAmbassadorOffer')}
                    </div>
                    {/* <ContactForm /> */}
                    <div   {...{ style: { marginTop: "24px" }, }}>

                    </div>
                    <Button
                        to=''
                        variant='primary'
                        onClick={(e) => {
                            e.preventDefault();
                            setLinkToAmbassadorRegistrationClicked(true);
                        }}
                    >
                        {t('ambassador.subscriptionRequest')}
                    </Button>
                </h2>
                <img src={img} alt="" style={{
                    gridArea: "image",
                    // height: "80%",
                    maxWidth: mobile ? "70vw" : "40vw",
                    marginTop: "10%",
                    borderRadius: "999999px",
                    placeSelf: "center"
                    // borderRadius: "200px 46px 46px 46px"
                    // borderRadius: "700px 399px 800px 400px"
                }} />

            </div>
        </WidthContainer>
    )
}

export { ContactAmbassadeurs };
