import React, { useEffect } from "react";
import { AppWrapper } from "../components/AppWrapper";
import { BackgroundEntreprises } from "../components/Background/BackgroundEntreprises";
import { BackgroundSection } from "../components/Background/BackgroundSection";
import { ContactAmbassadeurs } from "../components/ContactAmbassadeurs/ContactAmbassadeurs";
import Footer from "../components/ui/Footer";
import { Header } from "../components/Header";
import Banner from "../components/ui/Banner";

import { FaRegHeart } from "react-icons/fa";
import { Hero } from "../components/ui/Hero";
import Button from "../components/ui/Button";
import ResultsWrapper from "../components/sections/ResultsWrapper";
import firebase from "gatsby-plugin-firebase";
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function IndexPage({ location }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (firebase) {
            firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed);
            firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_ambassador_offer_page_viewed);
        }
    }, [firebase]);

    return (
        <AppWrapper>
            <Header location={location.pathname} />
            <Hero
                title={t("ambassador.heroTitle")}
                subtitle={t("ambassador.heroSubtitle")}
                img="/images/hero/ambassadeur-header-1.webp"
            >
                {/*<Button variant="primary" to="https://bo.behappiz.com/partner/login?next=/partner-auth">{t("ambassador.loginButton")}</Button>
                <Button variant="primary" to="https://bo.behappiz.com/partner/register">{t("ambassador.becomeAmbassadorButton")}</Button>*/}
                <Button variant="primary" to="/service-unavailable">{t("ambassador.loginButton")}</Button>
                <Button variant="primary" to="/service-unavailable">{t("ambassador.becomeAmbassadorButton")}</Button>

            </Hero>
            <BackgroundEntreprises>
                <Banner
                    addStyle="mb-16"
                    title={t("ambassador.bannerTitle")}
                    ctaAnchor={t("ambassador.testExperienceButton")}
                    ctaAction="https://happiz.onelink.me/5ePS/e6b33nm1"
                    img="/images/benefits/ambassadeur.webp"
                    ctaIcon={<FaRegHeart />}
                >
                    <p>
                        {t("ambassador.bannerDescription")}
                    </p>
                </Banner>
                <ResultsWrapper />
            </BackgroundEntreprises>
            <BackgroundSection ambassadeurs>
                <ContactAmbassadeurs />
            </BackgroundSection>
            <Footer />
        </AppWrapper>
    );
}
